import React, { Component } from 'react'
import "./section.css";

class Section extends Component{
  render(){
    return(
      <div id={this.props.styleName} className="sectionContainer">
          {this.props.children}
      </div>
    );
  }
}

export default Section