import './customization.css'
import React, { Component } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout/layout'
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby'
import { CustomizationText, CustomizationTags } from '../components/layout/strings'
import Section from "../components/layout/section"
import { CustomizationArrowImage  } from '../images/_imagesRootImport'

const step1Details ={image : null,
    stepNumber : CustomizationText.StepsData[0].stepNumber, 
    title: CustomizationText.StepsData[0].title,
    description: CustomizationText.StepsData[0].description};
const step2Details ={image : null,
    stepNumber : CustomizationText.StepsData[1].stepNumber, 
    title: CustomizationText.StepsData[1].title,
    description: CustomizationText.StepsData[1].description};
const step3Details ={image : null,
    stepNumber : CustomizationText.StepsData[2].stepNumber, 
    title: CustomizationText.StepsData[2].title,
    description: CustomizationText.StepsData[2].description};
const step4Details ={image : null,
    stepNumber : CustomizationText.StepsData[3].stepNumber, 
    title: CustomizationText.StepsData[3].title,
    description: CustomizationText.StepsData[3].description};

  
class CustomizationStaticImage extends Component {
    render(){
        return(
        <StaticQuery
            query={graphql`
            query {
                CustomizationMainImage: file(relativePath: { eq: "customization_main.jpg" }) {
                ...customizationMainImage
              }
              CustomizationStep1Image: file(relativePath: { eq: "customization_step1.jpg" }) {
                ...customizationStepImage
              }
              CustomizationStep2Image: file(relativePath: { eq: "customization_step2.jpg" }) {
                ...customizationStepImage
              }
              CustomizationStep3Image: file(relativePath: { eq: "customization_step3.jpg" }) {
                ...customizationStepImage
              }
              CustomizationStep4Image: file(relativePath: { eq: "customization_step4.jpg" }) {
                ...customizationStepImage
              }
            }
            `}
            render={data => <Customization data ={data}/>
            }
        />
        )
    }
}    

class Customization extends Component{
    constructor(props){
        super(props);

        step1Details.image = props.data.CustomizationStep1Image.childImageSharp.fluid;
        step2Details.image = props.data.CustomizationStep2Image.childImageSharp.fluid;
        step3Details.image = props.data.CustomizationStep3Image.childImageSharp.fluid;
        step4Details.image = props.data.CustomizationStep4Image.childImageSharp.fluid;
    }

    render(){
        return(
        <Layout selectedPage = "customization">
            <SEO 
                title={CustomizationTags.title}
                description={CustomizationTags.description} />

            <div className="customizationHeaderContainer">
                        <Img className="customizationHeaderImage" 
                          objectFit="cover" 
                          imgStyle={{objectPosition:"bottom"}}
                          fluid={this.props.data.CustomizationMainImage.childImageSharp.fluid} 
                          alt="Lounge background"/>
                        
                        <div className="customizationHeaderContent">
                            <h1 id="customizationHeader" className="pageHeader">{CustomizationText.MainHeading}</h1>
                            <p className="mainDescription">{CustomizationText.MainDescription}</p>
                        </div>
                </div>
            
            <Section styleName="customizationStepsContainer">
                <CustomizationStep details ={step1Details}/>
                <CustomizationStepReverse details ={step2Details}/>
                <CustomizationStep details ={step3Details}/>
                <CustomizationStepReverse details ={step4Details}/>
            </Section>
        
            <Section styleName= "customizationFaqsContainer"> 
                <h1 id="customizationFaqHeading" className="mainHeading">Frequently asked questions</h1>
                <CustomizationFaqList/>
            </Section>
        </Layout>
        )
    }
}

class CustomizationStep extends Component {
    render(){
        return <div className="customizationStepContainer">
            <CustomizationStepImage details = {this.props.details}/>
            <CutomizationStepDetails details = {this.props.details} isReverse = {false}/>
        </div>;
    }
}

class CustomizationStepReverse extends Component {
    render(){
        return <div className="customizationStepContainer">
            <CutomizationStepDetails details = {this.props.details} isReverse = {true}/>
            <CustomizationStepImage details = {this.props.details}/>
        </div>;
    }
}

class CutomizationStepDetails extends Component {
    render(){
        return <div id="cutomizationStepDetails" className="cutomizationStepDetails">
            <div id="cutomizationStepDetailsContainer" className="cutomizationStepDetailsContainer">
                <p className="cutomizationStepNumber">{this.props.details.stepNumber}</p>
                <div className="cutomizationStepNumberUnderline"></div>
                <h2 className="cutomizationStepNumberTitle">{this.props.details.title}</h2>
                <p className="cutomizationStepNumberDescription">{this.props.details.description}</p>
            </div>
        </div>;
    }
}

class CustomizationStepImage extends Component{
    render(){
        return <div className="customizationStepImageContainer">
            <Img className="customizationStepImage" 
                objectFit="cover" 
                fluid={this.props.details.image} 
                alt="Step Image"/>
        </div>
    }
}

class CustomizationFaqList extends Component{
    render(){
        const listItems = CustomizationText.FaqData.map((detail) =>
        <>
            <CustomizationFaq data = {detail}/>
            <div className="customizationFaqSeparator"></div>
        </>
        );
        return (
            <>{listItems}</>
        );
    }
}

class CustomizationFaq extends Component{
    render(){
        const answerID = "customizationFaqAnswer" + this.props.data.number;
        const arrorID = "arrowImage" + this.props.data.number;
        return (
            <div className="customizationFaqContainer">
                <p className="customizationFaqNumber">{this.props.data.number}</p>

                <div className="customizationFaqQuestionAnsContainer">
                    <div className="customizationFaqQuestionContainer">
                        <p id="customizationFaqQuestion" className="mainDescription">{this.props.data.question}</p>
                        <div className="arrowImageContainer" onClick={e => showAnswer(this.props.data.number)}>
                            <img id={arrorID} className="arrowImage" src={CustomizationArrowImage}/>
                        </div>
                    </div>
                    <p id={answerID} className="customizationFaqAnswer">{
                        this.props.data.isMultiline 
                        ? this.props.data.answer.split("\n").map((i,key) => 
                        {
                            return <div key={key}>{i}</div>;
                        })
                        : this.props.data.answer }</p>
                </div>
            </div>
        )
    }
}

function showAnswer(number){
    const id = "customizationFaqAnswer" + number;
    const arrorID = "arrowImage" + number;
    var answer = document.getElementById(id);
    var arrow = document.getElementById(arrorID);

    if(answer.style.display == "none" || answer.style.display == "")
    {
        answer.style.display = "block";
        arrow.style.transform = "rotate(180deg)";
        return;
    }
    answer.style.display = "none"
    arrow.style.transform = "rotate(0deg)";
}

export default CustomizationStaticImage

export const customizationMainImage = graphql`
  fragment customizationMainImage on File {
    childImageSharp {
      fluid(maxWidth: 2500, maxHeight: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const customizationStepImage = graphql`
fragment customizationStepImage on File {
  childImageSharp {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
`;